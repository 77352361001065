.header-clone {
  display: none;
}
.site-header {
  position: fixed !important;
  z-index: var(--zIndex__middle);
  background-color: transparent;

  &.fixed,
  &.float-header {
    position: fixed !important;
    padding: 1.5em 0;
    background-color: transparent;
    @media (--ipad){
      position: fixed !important;
    }
    @media (--ipad_mini){
      position: fixed !important;
    }
    @media (--phone){
      position: fixed !important;
    }
  }

  &.fixed {
    padding: 1.5em 0;
  }

  .header-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1em;
    @media (--desktop){
      padding: 0 2em;
    }

    &__main {
      z-index: calc( var(--zIndex__top) + 1);
    }
  }
}
