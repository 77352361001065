.btn {
  &&_themes_more {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    font: 400 1rem/1.2 'Rozha one', serif;
    transition: none;

    &:before {
      content: '';
      display: inline-block;
      width: 1.8em;
      height: 1.8em;
      margin-right: .5em;
      border-radius: 2em;
      border: 2px solid var(--blue);
      background: linear-gradient(transparent calc(50% - 1px), var(--blue) 0, var(--blue) calc(50% + 1px), transparent 0),
                  linear-gradient(90deg, transparent calc(50% - 1px), var(--blue) 0, var(--blue) calc(50% + 1px), transparent 0);
    }

    &:hover {
      color: var(--color__text);

      &:before {
        border-color: var(--color__text);
        background: linear-gradient(transparent calc(50% - 1px), var(--color__text) 0, var(--color__text) calc(50% + 1px), transparent 0),
                    linear-gradient(90deg, transparent calc(50% - 1px), var(--color__text) 0, var(--color__text) calc(50% + 1px), transparent 0);
      }
    }
  }
}
