.first-part__img {
  position: absolute;
  top: 50%;
  left: 2em;
  bottom: 0;
  right: 2em;
  text-align: center;
  transform-style: preserve-3d;
  perspective: 500px;
  @media (--desktop){
    top: 35%;
    left: 55%;
    right: 10%;
  }
}
