.join {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto !important;
  border-radius: .2em;

  > .wpb_column {
    flex: 1;
    align-self: stretch;
    @media (--phone){
      flex: none;
    }

    > .vc_column-inner {
      height: 100%;
      padding: 3em;
      @media (--phone){
        padding: 3em 2em;
      }
    }

    &:nth-child(2) {
      > .vc_column-inner {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .wpcf7 {
    margin-top: 1em;

    input {
      &[type="submit"] {
        border-color: var(--color__white);
        color: var(--color__white);
      }
    }
  }
}
