.colors {
  &_black {
    color: var(--color__text);
  }
  &_white {
    color: white;
  }
  &_gray {
    color: var(--color__text_gray);
  }
  &_blue {
    color: var(--color__blue);
  }
  &_yellow {
    color: yellow;
  }
  &_pink {
    color: var(--color__pink);
  }
}
