.language {
  @media (--ipad){
    text-align: left;
  }
  @media (--ipad_mini){
    text-align: left;
  }
  @media (--phone){
    text-align: left;
  }

  .menu {
    display: inline-flex;
    list-style: none;
  }

  .lang-item {
    flex: 1;
    padding: 0 .3em;
    border-right: 1px solid var(--color__white);
    vertical-align: middle;

    > a {
      display: block;
      text-transform: uppercase;
      font: 300 12px/1 Montserrat, sans-serif;
      color: var(--color__white);

      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}
