.btn-menu {
  display: block;
  z-index: calc( var(--zIndex__top) + 1);
  float: none;
  width: auto;
  height: auto;
  text-transform: capitalize;
  font: 300 12px/1.2 Montserrat, sans-serif;

  &:before {
    content: '';
    display: none;
  }

  &&.active {
    .menu-icon {
      flex-flow: row wrap;
      background-color: var(--bg__black);
    }

    .menu-icon__item {
      background-color: var(--bg__white);

      + .menu-icon__item {
        margin-left: 2px;
        margin-top: 0;
      }

      &:nth-child(odd) {
        width: 3px;
      }
      &:nth-child(even) {
        width: 2px;
      }
    }
  }
}
