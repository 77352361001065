#mainnav-mobi {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--zIndex__top);
  padding-top: 5em;
  padding-bottom: 3em;
  margin-top: 0;
  background-color: white;

  .menu-nav_esp-container,
  .menu-nav-container {
    padding: 0 2em 0;

    .menu-item {
      display: block;
      overflow: hidden;
      position: relative;
      width: 22em;
      margin: 0 auto;
      border-top: none;
      @media (--phone){
        width: 30em;
        max-width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        width: 100%;
        min-height: 0;
        background-color: color( var(--bg__blue) l(+20%));
        transition: top .3s, min-height .3s;
      }

      > a {
        text-transform: lowercase;
        text-align: center;
        font: 400 2.5rem/1.2 'Rozha one', serif;
        color: var(--color__text);
        opacity: 0;
      }

      &:hover {
        &:before {
          top: 0;
          min-height: 100%;
        }

        ~ .menu-item {
          &:before {
            top: 0;
          }
        }
      }
    }

    .menu {
      &.menu_active {
        .menu-item > a {
          opacity: 1;
          transition: .2s cubic-bezier(.2, .2, .9, .2) calc( (var(--i) - 1) * .15s );
        }
      }
    }
  }

  .menu-social-container {
    padding-bottom: 2em;

    .menu {
      text-align: center;

      &-item {
        overflow: hidden;
        display: inline-block;
        margin: 0 10px;
        border-top: none;

        a {
          display: inline-block;
          width: 30px;
          height: 30px;
          padding: 0;
          font-size: 0;
          opacity: 0;
        }
      }

      &.menu_active {
        .menu-item > a {
          opacity: 1;
          transition: .2s cubic-bezier(.2, .2, .9, .2) calc( (var(--i) - 1) * .15s + 1s );
        }
      }
    }
  }
}
@for $i from 1 to 6 {
  #mainnav-mobi .menu-nav-container .menu.menu_active .menu-item:nth-child($(i)) {
    --i: $i;
  }
  #mainnav-mobi .menu-nav_esp-container .menu.menu_active .menu-item:nth-child($(i)) {
    --i: $i;
  }
  #mainnav-mobi .menu-social-container .menu.menu_active .menu-item:nth-child($(i)) {
    --i: $i;
  }
}
@for $i from 0 to 2 {
  #mainnav-mobi .menu-social-container .menu-item-44$(i) > a {
    @if $i == 0 {
      background: url(../images/icon_facebook.png) center/contain no-repeat;
    } @else if $i == 1 {
      background: url(../images/icon_linkedin.png) center/contain no-repeat;
    } @else if $i == 2 {
      background: url(../images/icon_instagram.png) center/contain no-repeat;
    }
  }
}
