.first-part {
  & &__line {
    position: absolute;
    top: 53%;
    right: 0;
    left: 0;
    z-index: -2;
    @media (--desktop){
      top: 58%;
    }
  }
}
