.pum-container.pum-responsive.pum-responsive-medium {
  @media (--desktop) {
    max-width: 900px;
    width: 70%;
  }

  @media (--phone){
    .text {
      font-size: 12px;
    }
  }
}

.pum-theme-210 .pum-container,
.pum-theme-enterprise-blue .pum-container {
  padding: 3em;

  .owl-carousel {
    .owl-item {
      .item {
        padding: 10px 0 10px 24%;
        border: 2px solid transparent;
        @media (--phone){
          padding: 10px 20% 10px 30%;
        }

        a {
          pointer-events: none;
        }
      }
    }
  }

  .another_carousel_header {
    .fa {
      width: 0 !important;
      height: 0 !important;
      background-color: transparent !important;
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;

      &:before {
        content: '';
      }
    }

    .fa-angle-left {
      border-right: 10px solid #333 !important;
      transform: translateX(-10px);
    }

    .fa-angle-right {
      border-left: 10px solid #333 !important;
      transform: translateX(10px);
    }
  }
}

.pum-theme-enterprise-blue .pum-container .product-wrap {
  padding: 1em 0;
  text-align: right;
  @media (--phone){
    display: none;
  }

  img {
    width: 80%;
  }
}

.pum-theme-210 .pum-content + .pum-close,
.pum-theme-enterprise-blue .pum-content + .pum-close {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font: 300 3em Montserrat, sans-serif;
  color: var(--color__text);
}

#popmake-617,
#popmake-216 {
  @media (--phone){
    top: 10px !important;
  }

  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  @media (--desktop){
    .pc {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}

#pum-628,
#pum-556 {
  padding-right: 0;

  #popmake-628,
  #popmake-556 {
    display: flex !important;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-radius: 0;

    .popmake-content {
      max-width: 50em;
      margin: 0 auto;
    }

    .popmake-close {
      right: 5%;
    }
  }
}
