.spread {
  & &__head {
    max-width: 760px;
    padding: 6em 15% 0 0;
    margin: 0 auto !important;
    @mixin contentWrap;
    @media (--phone){
      padding: 6em 1em 0;
    }
  }
}
