@define-mixin formInput {
  padding-left: 1em;
  margin-top: .5em;
  border-color: color( var(--bg__gray) b(+20%) );
  border-radius: 1em;
  background-color: white;
  box-shadow: inset -2px -2px color(white b(+20%));
  font: 300 1rem/1.2 Montserrat, sans-serif;
  color: var(--color__text);

  &:focus {
    outline: 0;
    border-color: color(var(--bg__gray) b(+10%));
  }

  &::-ms-placeholder {
    opacity: 1;
    color: color( var(--color__text) ); 
    text-transform: lowercase;
  }

  &::placeholder {
    opacity: 1;
    color: color( var(--color__text) ); 
    text-transform: lowercase;
  }

  &:-ms-placeholder {
    opacity: 1;
    color: color( var(--color__text) ); 
    text-transform: lowercase;
  }
}
.wpcf7 {
  margin-top: 1em;

  input {
    &[type="text"],
    &[type="tel"],
    &[type="email"] {
      width: calc(50% - .5em);
      height: 2em;
      padding-left: .6em;
      @mixin formInput;
      @media (--phone){
        width: 100%;
      }
    }

    &[name="your-subject"],
    &[name="tel-382"] {
      margin-left: 1em;
      @media (--phone){
        margin-left: 0;
      }
    }

    &[type="submit"] {
      display: block;
      padding: 0 1em;
      margin: 1em auto 0 0;
      background-color: transparent;
      border-color: var(--color__text);
      border-radius: 2em;
      text-transform: lowercase;
      font: 400 1.2rem/1.2 'Rozha one', sans-serif;
      color: var(--color__text);

      &:hover {
        border-color: transparent;
      }
    }
  }

  textarea {
    padding-top: .4em;
    @mixin formInput;
    resize: none;
  }

  .wpcf7-not-valid-tip {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 0;

    &:before {
      content: '*';
      display: inline-block;
      margin-right: 1em;
      font-size: 1.5rem;
      line-height: .5;
      color: var(--color__red);
    }
  }

  .wpcf7-response-output.wpcf7-validation-errors {
    margin-top: 1em;
    font: 400 14px/1.2 Montserrat, sans-serif;
    color: var(--color__red);
  }
}
