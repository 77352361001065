.free-ingredients {
  &__item {
    flex: 1;
    text-align: center;
    @media (--phone){
      flex: none;
      width: calc(100% / 3);
      margin-top: 2em;
    }

    &:before {
      content: '';
      display: block;
      width: 2em;
      height: 2em;
      margin: 0 auto;
      background: center/contain no-repeat;
    }
  }

  &&_themes_w {
    .free-ingredients__item {
      padding: 10px 0;
      border: 1px solid white;
      border-radius: 5px;
      text-align: center;

      + .free-ingredients__item {
        margin-left: 5px;
      }
    }
  }
}

@each $i in gmo, sugar, egg, gluten, dairy {
  .free-ingredients__item_$(i):before {
    background-image: url(../images/icon_$(i).png);
  }
}
