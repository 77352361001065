.menu-icon {
  & &__item {
    display: block;
    width: 50%;
    height: 2px;
    background-color: var(--bg__black);

    + .menu-icon__item {
      margin-top: 2px;
    }
  }
}
