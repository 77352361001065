.btn {
  &&_themes_square {
    padding: 0 1em;
    margin-top: .4em;
    border-radius: 1em;
    border: 1px solid var(--color__text);
    font: 400 1.2rem/1.2 'Rozha one', sans-serif;
    color: var(--color__text);

    &:hover {
      background-color: var(--bg__black);
      color: var(--color__white);
    }
  }
}
