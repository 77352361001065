.animate {
  &_fade {
    animation: fade 1s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_fadeUp {
    animation: fadeUp 1s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_fadeIn {
    animation: fadeIn 1s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_fadeDown {
    animation: fadeDown 1s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_fadeLeft {
    animation: fadeLeft 1s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_line_scale {
    transform-origin: center top;
    animation: scale 3s cubic-bezier(.2, .8, .5, .9) running infinite;
  }

  &_resize {
    animation: resize 1.5s cubic-bezier(.2, .8, .5, .9) both paused;
  }

  &_running {
    animation-play-state: running;
  }

  &_btn_more {
    &:before {
      animation: rotate 1s cubic-bezier(.2, .8, .5, .9) both paused;
    }

    > span {
      overflow: hidden;
      white-space: nowrap;
      animation: scale 2s cubic-bezier(.2, .8, .5, .9) both paused;
    }

    &.animate_running {
      &:before,
      > span {
        animation-play-state: running;
      }
    }
  }
}

@for $i from 1 to 5 {
  .animate_delay_$(i) {
    --i: $i;
    animation-delay: calc(var(--i) * .3s);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(4em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-4em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-4em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: rotate(0);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 10em;
  }
}

@keyframes resize {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  65% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1.15);
  }
  90% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale {
  0% {
    transform: scale(1, .1);
  }
  100% {
    transform: scale(1, 1);
  }
}
