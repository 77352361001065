.know-us {
  padding: 6em 0 20%;
  background: url(../images/an-0.png) center bottom/35% auto no-repeat var(--bg__gray_dark);
  @media (--phone){
    padding: 5em 0 45%;
    background-size: 75% auto;
  }

  .sa_hover_container {
    padding: 0 !important;
    min-height: auto !important;
    @media (--phone){
      padding-right: 1em !important;
      padding-left: 1em !important;
    }
  }

  .owl-item.center {
    font: 300 1.2rem/1.4 Montserrat, sans-serif;
    color: var(--color__text);
    text-align: center;
    animation: toSmall 2s ease both;
  }

  .owl-dots {
    transform: translateY(2em);
  }

  .white .sa_owl_theme .owl-nav .owl-prev,
  .white .sa_owl_theme .owl-nav .owl-next {
    width: 20px !important;
    height: 20px !important;
    background-color: transparent !important;
    filter: invert(1) drop-shadow(0 0 1px color(black l(+20%)));
    @media (--phone){
      display: none;
      visibility: hidden;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .white .sa_owl_theme .owl-nav .owl-prev {
    transform: translateX(-2em);
  }

  .white .sa_owl_theme .owl-nav .owl-next {
    transform: translateX(2em);
  }

  .autohide-arrows .owl-nav {
    display: block !important;
    @media (--phone){
      display: none !important;
    }
  }

  .sa_owl_theme .owl-dots .owl-dot {
    span {
      background-color: white !important;
    }

    &.active {
      span {
        background-color: var(--bg__black) !important;
      }
    }
  }
}

@keyframes toSmall {
  0% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}
