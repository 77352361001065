.rainmaker_form {
  .rm_form_label {
    display: none;
  }

  .rm_form_field[type="email"] {
    width: 22em;
    height: 2em;
    max-width: 100%;
    margin: 2em auto 0;
    background-color: var(--bg__white);
    border: none;
    border-radius: 1em;
    font: 300 1rem/1.2 Montserrat, sans-serif;
    color: var(--color__text);
    box-shadow: inset -2px -2px color(white b(+20%));

    &::placeholder {
      opacity: 1;
      color: color( var(--color__text) ); 
    }
    &::-ms-placeholder {
      opacity: 1;
      color: color( var(--color__text) ); 
    }
    &:-ms-placeholder {
      opacity: 1;
      color: color( var(--color__text) ); 
    }

    &:focus {
      outline: 0;
      border-color: color(var(--bg__gray) b(+20%));
    }
  }

  .es-field-wrap {
    margin-bottom: 0;
  }

  .rm_form_field.rm_button {
    display: block;
    padding: 0 1em;
    margin-top: .6em;
    text-transform: lowercase;
    background-color: transparent !important;
    border-color: var(--color__text);
    border-radius: 1em;
    font: 400 1.2rem/1.2 'Rozha one', sans-serif;
    color: var(--color__text);

    &:hover {
      background-color: var(--color__text) !important;
      color: var(--color__white);
    }
  }

  .rm_form_el_set {
    width: auto;
    padding: 0;
    @media (--phone){
      vertical-align: bottom;
    }
  }
}
