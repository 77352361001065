.btn {
  &&_themes_continue {
    &:before {
      content: '';
      display: block;
      width: 3.5em;
      height: 3.5em;
      margin: 0 auto 1em;
      background: svg-inline(icon_continue) center/contain no-repeat;
    }

    &&_b {
      color: var(--color__text);

      &:before {
        background-image: svg-inline(icon_continue);
      }
    }

    &&_w {
      color: white;

      &:before {
        background-image: svg-inline(icon_continue_w);
      }
    }
  }
}
