:root {
  --gray: #f2f4fa;
  --blue: #7bcff4;
  --blue_drak: #55c1f0;
  --yellow: #efff00;
  --black: #333;
  --green_light: #a0ffdf;
  --green: #37ffbc;
  --green_dark: #00cc87;
  --white: white;

  --bg__gray: var(--gray);
  --bg__gray_dark: #ccd5de;
  --bg__blue: var(--blue);
  --bg__blue_dark: var(--blue_drak);
  --bg__pink: rgb(243, 195, 212);
  --bg__black: var(--black);
  --bg__white: var(--white);

  --color__cyan: #41feec;
  --color__blue: var(--blue);
  --color__yellow: var(--yellow);
  --color__pink: rgb(255, 113, 184);
  --color__red: red;
  --color__text_gray: var(--gray);
  --color__text: var(--black);
  --color__white: white;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;
}

@svg-load icon_continue url(../images/icon_continue.svg) {
  fill: black;
}
@svg-load icon_continue_w url(../images/icon_continue.svg) {
  fill: white;
}

@define-mixin contentWrap {
  width: calc(100% - 2em);
}
