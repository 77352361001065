.language__item {
  display: inline-block;
  padding: 0 .4em;
  font: 300 14px/1.1 Montserrat, sans-serif;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  & + & {
    border-left: 1px solid var(--color__white);
  }

  > a {
    color: var(--color__white);

    &:hover {
      text-decoration: underline;
    }
  }
}
