.feature {
  > .vc_row {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
  }

  .vc_col-sm-4 {
    flex: 1;
    padding: 5em;
    @media (--phone){
      flex: none;
    }

    > .vc_column-inner {
      height: 100%;
    }

    &:nth-child(1) {
      background-color: var(--green_light);
    }
    &:nth-child(2) {
      background-color: var(--green);
    }
    &:nth-child(3) {
      background-color: var(--green_dark);
    }
  }
}
