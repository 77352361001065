.first-part {
  & &__head {
    position: absolute;
    top: 40%;
    right: 1em;
    left: 2em;
    transform: translateY(-50%);
    z-index: -1;
    @media (--desktop){
      top: 45%;
      right: 50%;
    }

    &-title {
      font-size: 2rem;
    }
  }
}
