.first-part {
  position: relative;
  background-position: 50% 70% !important;
  z-index: 1;
  @media (--phone){
    background-position: 50% 60% !important;
    background-size: contain !important;
  }

  .wpb_column {
    position: static;
  }
}
