.food {
  & &__cheese {
    position: relative;
    z-index: var(--zIndex__bottom);
    padding: 5em 0 4em;
    @mixin contentWrap;
    max-width: none;
    background-color: var(--bg__pink);
  }
}
