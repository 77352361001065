.term {
  padding-top: 0 !important;
  background-color: var(--bg__gray_dark);

  article {
    padding-top: 6em;

    &:focus {
      outline: 0;
    }
  }

  ul {
    padding-left: 1.5em;
  }

  a {
    color: var(--color__black);
  }

  @media (--phone){
    .text {
      font-size: 12px;
    }
  }
}
