.footer-widgets {
  overflow: hidden;
  border-top: 2px solid var(--bg__gray);
  padding: 3em 2em;
  text-align: center;
  @media (--desktop){
    text-align: left;
  }

  @media (--ipad){
    .sidebar-column + .sidebar-column {
      margin-top: 2em;
    }
  }
  @media (--ipad_mini){
    .sidebar-column + .sidebar-column {
      margin-top: 2em;
    }
  }
  @media (--phone){
    .sidebar-column + .sidebar-column {
      margin-top: 2em;
    }
  }

  .site-logo {
    font: 800 1.1rem/1.4 RockWell, serif;
  }

  .site-copyright {
    font-size: 14px;
    line-height: 1.4;
    @media (--phone){
      font-size: 12px;
    }
  }

  .widget {
    margin-bottom: 0;

    &-title {
      display: none;
    }
  }

  .menu {
    .menu-item {
      font: 400 14px/1.2 'Rozha one', serif;
      text-transform: lowercase;
    }
  }

  #followusonwidget-2 ul.wpFUP:before {
    content: 'follow us';
  }

  #followusonwidget-3 ul.wpFUP:before {
    content: 'síguenos';
  }

  ul.wpFUP {
    padding: 0 .5em;
    text-align: center;

    &:before {
      display: inline-block;
      font: 400 14px/1.2 'Rozha one', serif;
      text-transform: lowercase;

    }

    li {
      padding-left: 14px !important;

      img {
        width: 1.4em;
        height: 1.4em;
      }
    }
  }
}
