.spread__products {
  position: relative;
  height: 100vh;

  .wpb_single_image {
    position: absolute;
    top: 0;
    max-width: 390px;
    width: 28%;

    &:first-child {
      left: 10%;
    }

    &:nth-child(2) {
      left: 35%;
      z-index: 2;
    }

    &:nth-child(3) {
      left: 50%;
    }

    &:last-child {
      left: calc(100% / 3 * 2 + 7%);
    }
  }
}
