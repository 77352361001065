.menu-icon {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin: .4em auto 1em;
  padding-left: 0;
  background-color: var(--bg__white);
  border-radius: 50%;
  list-style: none;
}
